import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import Slider from 'react-slick';
import marked from 'marked';

import {
  StyledCarouselPageWrapper,
  StyledCarouselHeadingContainer,
  StyledCarouselHeading,
  StyledCarouselCopy,
  StyledCarouselArrowWrapper,
  StyledCarouselHeadingImages,
  StyledCsrReelSlideWrapper,
} from './styles';
import theme from '../Layout/theme';

import { useWindowWidth } from '../../context/WindowWidthContext';
import {
  isTablet,
  isTabletOrWider,
  slideKeyFromData,
  slideTypeFromData,
} from '../../helpers';

import CarouselSlide from '../CarouselSlide';
import ArrowButton from '../ArrowButton';

/**
 * Calculates the slideWidth multiplier based on args
 * This multiplier is used in the CSS to determine actual width
 * @param {Number} width window width
 * @param {Number} height window height
 * @param {Number} index slide index
 * @param {Boolean} isIE is it IE
 * @param {String} type the type of slide, i.e: heading, image, svg
 * @param {String} viewName the type of carousel, i.e: top, middle
 */
function calcSlideWidth({ width, height, index, type, viewName, isIE }) {
  if (width >= 1024 && width > height && index === 0) {
    if (height < 600) {
      // if its over laptop, is landscape, is first slide, but height is too short
      return 3.2;
    }

    if (viewName === 'awards') {
      return 1.3;
    }
    // if its over laptop, is landscape and is first slide
    return 2.1;
  }

  // if its the first slide and its type is heading
  if (index === 0 && type === 'heading') return 1;

  // icon slides under tablet width
  if (viewName === 'middle-carousel' && width < theme.minWidthTablet) return 1;

  if (viewName === 'awards') {
    return 0.705;
  }

  if (width >= 1024 && viewName === 'middle-carousel' && height < 600) {
    // icon slides over laptop width
    return isIE ? 1.4 : 1.1;
  }

  // slide width over laptop
  if (width >= 1370) return isIE ? 1.1 : 0.85;

  // default width based on tablet or not
  return isTablet(width) ? (isIE ? 0.9 : 0.65) : isIE ? 1 : 0.75;
}

export default function Carousel(props) {
  const [disablePrevArrow, setDisablePrevArrow] = React.useState(true);
  const { width, height } = useWindowWidth();
  let slideChangeTimeout;
  let sliderRef;

  const settings = {
    // Desktop and default settings
    easing: 'ease-in-out',
    variableWidth: true,
    swipeToSlide: true,
    infinite: false,
    arrows: false,
    dots: false,
    beforeChange: (current, next) => {
      // clear the timeout if one exists
      clearTimeout(slideChangeTimeout);
    },
    afterChange: current => {
      // if (current >= props.data.carouselSlides.length - 2) {
      //   slideChangeTimeout = setTimeout(() => {
      //     sliderRef.slickGoTo(props.data.carouselSlides.length - 3);
      //   }, 20);
      // }
      setDisablePrevArrow(current > 0 ? false : true);
    },
    responsive: [
      {
        // Laptop
        breakpoint: theme.minWidthDesktop,
        settings: {
          afterChange: current => {
            setDisablePrevArrow(current > 0 ? false : true);
          },
        },
      },
      // {
      //   // Tablet
      //   breakpoint: theme.minWidthLaptop,
      //   settings: {
      //     afterChange: current => {
      //       if (current >= props.data.carouselSlides.length - 1) {
      //         slideChangeTimeout = setTimeout(() => {
      //           sliderRef.slickGoTo(props.data.carouselSlides.length - 2);
      //         }, 20);
      //       }
      //       setDisablePrevArrow(current > 0 ? false : true);
      //     },
      //   },
      // },
      {
        // Mobile
        breakpoint: theme.minWidthTablet,
        settings: {
          afterChange: current => {
            setDisablePrevArrow(current > 0 ? false : true);
          },
        },
      },
    ],
  };

  function decrementSlide() {
    sliderRef.slickPrev();
  }

  function incrementSlide(width, height) {
    // console.log(sliderRef.state.currentSlide, sliderRef.state.slideCount - 1);
    // if (
    //   width > 1570 &&
    //   sliderRef.state.currentSlide >= sliderRef.state.slideCount - 4
    // ) {
    //   console.log('1');
    //   sliderRef.slickGoTo(0);
    // } else if (
    //   width > 1000 &&
    //   width > height &&
    //   sliderRef.state.currentSlide >= sliderRef.state.slideCount - 3
    // ) {
    //   console.log('2');
    //   // Laptop width and greater in landscape scroll back to start when last 4 slides show
    //   sliderRef.slickGoTo(0);
    // } else if (
    //   width > theme.minWidthLaptop &&
    //   // width < height &&
    //   sliderRef.state.currentSlide >= sliderRef.state.slideCount - 3
    // ) {
    //   console.log('3');
    //   // Laptop width and greater in portrait scroll back to start when last 3 slides show
    //   sliderRef.slickGoTo(0);
    // } else if (
    //   width > theme.minWidthTablet &&
    //   sliderRef.state.currentSlide >= sliderRef.state.slideCount - 2
    // ) {
    //   console.log('4');
    //   // On tablet portrait, scroll back to start when last 2 slides show
    //   sliderRef.slickGoTo(0);
    // } else if (sliderRef.state.currentSlide >= sliderRef.state.slideCount - 2) {
    //   // On mobile scroll back to start when last slide shows
    //   sliderRef.slickGoTo(0);
    // } else {
    //   console.log('5');
    //   // Else just handle regular scroll forward
    //   sliderRef.slickNext();
    // }

    // console.log(sliderRef.state.slideCount, sliderRef.state.currentSlide);
    if (sliderRef.state.currentSlide === sliderRef.state.slideCount - 1) {
      sliderRef.slickGoTo(0);
    } else if (
      width >= 1440 &&
      sliderRef.state.currentSlide >= sliderRef.state.slideCount - 3
    ) {
      sliderRef.slickGoTo(0);
    } else if (
      width >= theme.minWidthTablet &&
      sliderRef.state.currentSlide >= sliderRef.state.slideCount - 2
    ) {
      sliderRef.slickGoTo(0);
    } else {
      sliderRef.slickNext();
    }
  }

  /**
   * START
   * To tell the Nav what page we are on
   */
  // const [ref, inView] = useInView({
  //   threshold: 0.85,
  // });

  // React.useEffect(() => {
  //   if (inView) {
  //     // props.setActiveView(inView, props.viewName);
  //     if (props.viewName === 'top-carousel') {
  //       window.location.hash = 'about';
  //     }
  //     if (props.viewName === 'middle-carousel') {
  //       window.location.hash = 'services';
  //     }
  //   }
  // }, [inView]);
  // END

  /**
   * START
   * Set the click event for the "Get in touch" <p>
   * This is a terrible hack and I hate my life
   */

  // END

  /**
   * START
   * handle carousel slide reset when it goes out of view
   */
  // const [carouselRef, carouselIsInView] = useInView({ threshold: 0.05 });

  // React.useEffect(() => {
  //   if (!carouselIsInView) {
  //     sliderRef.slickGoTo(0);
  //   }
  // }, [carouselIsInView]);
  // END

  function handleWheelEvent(e, width, height) {
    if (e.deltaX > 15) return incrementSlide(width, height);
    if (e.deltaX < -15) return decrementSlide();
  }

  const slides = !isTabletOrWider(width)
    ? [...props.data.carouselSlides]
    : [
        {
          type: 'heading',
          heading: props.data.heading,
          copy: {
            copy: props.data.copy.copy,
            images: props.data.copy.images || [],
          },
        },
        ...props.data.carouselSlides,
      ];
  return (
    <StyledCarouselPageWrapper viewName={props.viewName}>
      {/* Tablet only, Heading and Copy */}
      {!isTabletOrWider(width) ? (
        <StyledCarouselHeadingContainer>
          <StyledCarouselHeading
            dangerouslySetInnerHTML={{ __html: props.data.heading }}
          />

          <StyledCarouselCopy
            dangerouslySetInnerHTML={{
              __html: marked(props.data.copy.copy),
            }}
          />
          {props.data.copy.images && (
            <StyledCarouselHeadingImages>
              {props.data.copy.images.map(({ url, alt }, index) => {
                return <img key={index} src={url} alt={alt} />;
              })}
            </StyledCarouselHeadingImages>
          )}
        </StyledCarouselHeadingContainer>
      ) : null}

      <StyledCsrReelSlideWrapper
        onWheel={e => handleWheelEvent(e, width, height)}
        isTablet={isTablet(width)}
        viewName={props.viewName}
      >
        <Slider
          ref={thisRef =>
            thisRef ? (sliderRef = thisRef.innerSlider) : undefined
          }
          {...settings}
        >
          {slides.map((slide, index) => (
            <CarouselSlide
              width={calcSlideWidth({
                viewName: props.viewName,
                type: slide.type,
                isIE: props.isIE,
                height,
                width,
                index,
              })}
              type={slideTypeFromData(slide)}
              key={index}
              isTablet={isTablet(width)}
              viewName={props.viewName}
              windowHeight={height}
              index={index}
              data={slide}
              setSelectedCard={props.setSelectedCard || (() => null)}
            />
          ))}
        </Slider>
      </StyledCsrReelSlideWrapper>

      <StyledCarouselArrowWrapper>
        <ArrowButton
          disablePrevArrow={disablePrevArrow}
          onClick={() => decrementSlide()}
          arrowDirection="left"
          width={width}
        />
        <ArrowButton
          onClick={() => incrementSlide(width, height)}
          arrowDirection="right"
          width={width}
        />
      </StyledCarouselArrowWrapper>
    </StyledCarouselPageWrapper>
  );
}

Carousel.propTypes = {
  data: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    copy: PropTypes.shape({
      copy: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  viewName: PropTypes.string,
  scrollTo: PropTypes.func,
  isIE: PropTypes.bool,
};
