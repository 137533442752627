import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import { useSpring, animated, config } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import marked from 'marked';

import {
  StyledCarouselHeading,
  StyledCarouselCopy,
  StyledCarouselHeadingImages,
  StyledAwardHeading,
  StyledAwardCopy,
} from '../Carousel/styles';

import {
  StyledCarouselSlideContainer,
  StyledCarouselImageContainer,
  StyledIconSlideHeading,
  StyledIconSlideCopy,
  StyledAwardSlideContainer,
  StyledCsrReelImageContainer,
  StyledCsrReelSlideContainer,
} from './styles';
import ReversableSlideCard from './ReversableSlideCard';
import { useWindowWidth } from '../../context/WindowWidthContext';

// const lottieInstagramOptions = {
//   animationData: Instagram_icon,
//   autoplay: false,
//   direction: 1,
//   loop: false,
//   rendererSettings: {
//     preserveAspectRatio: "xMidYMid meet",
//   },
// };

export default function CarouselSlide(props) {
  const [autoplay, setAutoplay] = React.useState(false);
  const [direction, setDirection] = React.useState(-1);
  const [slideRef, slideIsInView] = useInView({
    threshold: 0.05,
    triggerOnce: true,
  });
  const { width } = useWindowWidth();

  const imageInViewStyleProps = useSpring({
    transform: slideIsInView
      ? `translateY(0px)`
      : `translateY(${props.index % 2 === 0 ? '40px' : '-40px'})`,
    opacity: slideIsInView ? 1 : 0,
    from: { opacity: 0 },
    config: config.molasses,
    // delay: 125 * props.index,
  });

  const headingInViewStyleProps = useSpring({
    opacity: slideIsInView ? 1 : 0,
    from: { opacity: 0 },
    config: config.molasses,
    // delay: 150,
  });

  function playForward() {
    setAutoplay(true);
    setDirection(1);
  }

  function playReverse() {
    setAutoplay(true);
    setDirection(-1);
  }

  function calcIconHeight(windowHeight) {
    if (windowHeight < 570) return '12%';
    if (windowHeight < 800) return '18%';
    return '35%';
  }

  switch (props.type) {
    case 'image':
      if (props.viewName === 'csrReel') {
        const margins = [
          200,
          100,
          100,
          100,
          100,
          100,
          200,
          100,
          200,
          150,
          100,
          100,
        ];

        const marginTop =
          width > 640 ? margins[(props.index - 1) % margins.length] - 100 : 0;

        return (
          <StyledCsrReelSlideContainer
            onMouseEnter={playForward}
            onMouseLeave={playReverse}
            isTablet={props.isTablet}
            viewName={props.viewName}
            width={props.width}
            type={props.type}
            ref={slideRef}
          >
            <animated.div style={imageInViewStyleProps}>
              <StyledCsrReelImageContainer>
                <img
                  style={{
                    marginTop: `${marginTop}px`,
                  }}
                  src={props.data.image.fluid.src}
                  alt={props.data.image.description}
                />
              </StyledCsrReelImageContainer>
            </animated.div>
          </StyledCsrReelSlideContainer>
        );
      }
      // Image slide
      return (
        <StyledCarouselSlideContainer
          onMouseEnter={playForward}
          onMouseLeave={playReverse}
          isTablet={props.isTablet}
          viewName={props.viewName}
          width={props.width}
          type={props.type}
          ref={slideRef}
        >
          <animated.div style={imageInViewStyleProps}>
            <a href={props.data.imageHyperlink}>
              <StyledCarouselImageContainer>
                <img
                  src={props.data.image.fluid.src}
                  alt={props.data.image.description}
                />
              </StyledCarouselImageContainer>
            </a>
          </animated.div>
        </StyledCarouselSlideContainer>
      );

    case 'heading':
      // Heading slide

      const copy = marked(props.data.copy.copy);

      const isAwardSlide =
        props.viewName === 'awards' && !props.data.copy.images;

      const margins = [50, 0, 120];

      if (isAwardSlide) {
        const marginTop = margins[(props.index - 1) % margins.length];

        return (
          <StyledAwardSlideContainer
            renderWideTextSlide={true}
            viewName={props.viewName}
            isTablet={props.isTablet}
            width={props.width}
            type={props.type}
            ref={slideRef}
            marginTop={marginTop}
          >
            <animated.div style={headingInViewStyleProps}>
              <StyledAwardHeading
                // dangerouslySetInnerHTML={{ __html: props.data.heading }}
                viewName={props.viewName}
              >
                <h2 dangerouslySetInnerHTML={{ __html: props.data.heading }} />
                <h3>
                  <em>believe that</em>
                </h3>
                <StyledAwardCopy dangerouslySetInnerHTML={{ __html: copy }} />
                {props.data.copy.images && (
                  <StyledCarouselHeadingImages>
                    {props.data.copy.images.map(({ url, alt }, index) => {
                      return <img key={index} src={url} alt={alt} />;
                    })}
                  </StyledCarouselHeadingImages>
                )}
              </StyledAwardHeading>
            </animated.div>
          </StyledAwardSlideContainer>
        );
      }

      return (
        <StyledCarouselSlideContainer
          renderWideTextSlide={true}
          viewName={props.viewName}
          isTablet={props.isTablet}
          width={props.width}
          type={props.type}
          ref={slideRef}
          isAward={props.viewName === 'awards' && !props.data.copy.images}
          isBenefitsHeading={props.viewName === 'benefits'}
        >
          <animated.div style={headingInViewStyleProps}>
            <StyledCarouselHeading
              dangerouslySetInnerHTML={{ __html: props.data.heading }}
              viewName={props.viewName}
              style={{
                maxWidth: props.viewName === 'services' && '480px',
              }}
            />

            <StyledCarouselCopy
              dangerouslySetInnerHTML={{ __html: copy }}
              style={{
                maxWidth: props.viewName === 'csrReel' && '470px',
                maxWidth: props.viewName === 'intro' && '450px',
                // maxWidth: props.viewName === 'services' && '370px',
              }}
            />
            {props.data.copy.images && (
              <StyledCarouselHeadingImages>
                {props.data.copy.images.map(({ url, alt }, index) => {
                  return <img key={index} src={url} alt={alt} />;
                })}
              </StyledCarouselHeadingImages>
            )}
          </animated.div>
        </StyledCarouselSlideContainer>
      );
    case 'svg':
      // SVG and copy slide
      return (
        <animated.div style={imageInViewStyleProps}>
          <StyledCarouselSlideContainer
            onMouseEnter={playForward}
            onMouseLeave={playReverse}
            isTablet={props.isTablet}
            viewName={props.viewName}
            width={props.width}
            type={props.type}
            ref={slideRef}
          >
            <Lottie
              key={props.data.iconJson.file.url}
              height={calcIconHeight(props.windowHeight)}
              direction={direction}
              width={'100%'}
              options={{
                path: props.data.iconJson.file.url,
                autoplay: false,
                direction: 1,
                loop: false,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid meet',
                },
              }}
            />
            <StyledIconSlideHeading viewName={props.viewName}>
              {props.data.svgSlideHeading}
            </StyledIconSlideHeading>

            <StyledIconSlideCopy windowHeight={props.windowHeight}>
              {props.data.svgSlideCopy.svgSlideCopy}
            </StyledIconSlideCopy>
          </StyledCarouselSlideContainer>
        </animated.div>
      );

    case 'card':
      return (
        <animated.div style={imageInViewStyleProps}>
          <StyledCarouselSlideContainer
            onMouseEnter={playForward}
            onMouseLeave={playReverse}
            isTablet={props.isTablet}
            viewName={props.viewName}
            width={props.width}
            type={props.type}
            ref={slideRef}
          >
            <ReversableSlideCard
              data={props.data}
              index={props.index}
              setSelectedCard={props.setSelectedCard}
            />
          </StyledCarouselSlideContainer>
        </animated.div>
      );

    default:
      return (
        <StyledCarouselSlideContainer
          viewName={props.viewName}
          width={props.width}
          isTablet={props.isTablet}
        >
          no data type known
        </StyledCarouselSlideContainer>
      );
  }
}

CarouselSlide.propTypes = {
  viewName: PropTypes.string,
  isTablet: PropTypes.bool.isRequired,
  width: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  windowHeight: PropTypes.number,
  data: PropTypes.any.isRequired,
};
