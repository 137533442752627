import React, { useEffect, useState } from 'react';
import { useSpring } from 'react-spring';
import PropTypes from 'prop-types';

import {
  NavMenuWrapper,
  MenuContent,
  NavContactWrapper,
  MenuWrapper,
  HeadingLink,
  SubLink,
  SubLinkContainer,
  MenuBackdrop,
  LargeScreenNavMenuWrapper,
  SmallerNavMenuWrapper,
} from './styles';

import HamburgerMenu from '../HamburgerMenu';
import { useScroll } from '../Scroller';
import { menuData } from '../../data/menuData';
import { ContactPopup } from './ContactPopup';
import { useWindowWidth } from '../../context/WindowWidthContext';

const Nav = ({
  isCulturePage,
  showNav,
  setShowNav,
  showContactPage,
  setShowContactPage,
  isMobileLandscape = false,
}) => {
  const { scrollTo } = useScroll();
  // Nav animations
  const [animateNav, setAnimateNav] = useState(false);
  let navTimeout;
  const { width } = useWindowWidth();

  useEffect(() => {
    const el = document.getElementsByTagName('body');

    if (el && el[0]) {
      if (animateNav) {
        el[0].style.overflowY = 'hidden';
      } else {
        el[0].style.overflowY = 'auto';
      }
    }
  }, [animateNav]);

  useEffect(() => {
    if (showContactPage) {
      setShowNav(true);
      setAnimateNav(true);
    }
  }, [showContactPage]);

  const navAnimation = useSpring({
    config: { mass: 5, tension: 150, friction: 10, clamp: true },
    opacity: animateNav ? 1 : 0,
  });

  function toggleHamburgerMenu(pageNum) {
    toggleHidePage();
    scrollTo(pageNum);
  }

  function toggleHidePage() {
    clearTimeout(navTimeout);

    if (showNav === false) {
      setShowNav(true);
      setAnimateNav(true);
    } else if (showNav === true) {
      setAnimateNav(false);

      navTimeout = setTimeout(() => {
        setShowNav(false);
        setShowContactPage(false);
      }, 500);
    }
  }

  // Return nothing if isMobileLandscape
  if (isMobileLandscape) return null;

  return (
    <>
      <HamburgerMenu
        isMenuOpen={showNav}
        animateNav={animateNav}
        toggle={toggleHidePage}
        isCulturePage={isCulturePage}
      />
      {showContactPage ? (
        <NavContactWrapper style={navAnimation}>
          <ContactPopup animateNav={animateNav} />
        </NavContactWrapper>
      ) : (
        <>
          <LargeScreenNavMenuWrapper style={{ zIndex: !showNav && '-1' }}>
            <NavMenuWrapper style={navAnimation}>
              <MenuContent>
                <NavMenu
                  isSecondary={isCulturePage}
                  scrollTo={toggleHamburgerMenu}
                  showContactPage={() => setShowContactPage(true)}
                />
              </MenuContent>
            </NavMenuWrapper>
            <MenuBackdrop
              animateNav={animateNav}
              className={`${animateNav && 'animate-backdrop'}`}
            />
          </LargeScreenNavMenuWrapper>
          <SmallerNavMenuWrapper
            style={{ ...navAnimation, zIndex: !showNav && '-1' }}
          >
            <MenuContent>
              <NavMenu
                isSecondary={isCulturePage}
                scrollTo={toggleHamburgerMenu}
                showContactPage={() => setShowContactPage(true)}
              />
            </MenuContent>
          </SmallerNavMenuWrapper>
          {/* {width >= 1440 && (
            <NavMenuWrapper style={{ zIndex: !showNav && '-1' }}>
              <NavMenuWrapper style={navAnimation}>
                <MenuContent>
                  <NavMenu
                    isSecondary={isCulturePage}
                    scrollTo={toggleHamburgerMenu}
                    showContactPage={() => setShowContactPage(true)}
                  />
                </MenuContent>
              </NavMenuWrapper>
              <MenuBackdrop
                animateNav={animateNav}
                className={`${animateNav && 'animate-backdrop'}`}
              />
            </NavMenuWrapper>
          )}

          {width < 1440 && (
            <NavMenuWrapper
              style={{ ...navAnimation, zIndex: !showNav && '-1' }}
            >
              <MenuContent>
                <NavMenu
                  isSecondary={isCulturePage}
                  scrollTo={toggleHamburgerMenu}
                  showContactPage={() => setShowContactPage(true)}
                />
              </MenuContent>
            </NavMenuWrapper>
          )} */}
        </>
      )}
    </>
  );
};

Nav.propTypes = {
  isMobileLandscape: PropTypes.bool,
  activeView: PropTypes.string,
  scrollTo: PropTypes.func,
};

export default Nav;

const NavMenu = ({ isSecondary = false, scrollTo, showContactPage }) => {
  const [currentLocation, setCurrentLocation] = useState(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setCurrentLocation(window.location.hash);
    }
  }, []);

  return (
    <MenuWrapper>
      {menuData.map((el, index) => {
        const { heading, path, links, secondary } = el;
        const isCurrent = isSecondary === secondary;
        const isContact = heading.toLowerCase() === 'contact';

        return (
          <div key={index}>
            {isContact ? (
              <HeadingLink
                as="button"
                isSecondary={isSecondary}
                isCurrent={isCurrent}
                onClick={() => showContactPage()}
              >
                Contact
              </HeadingLink>
            ) : isCurrent ? (
              <HeadingLink
                as="button"
                isSecondary={isSecondary}
                isCurrent={isCurrent}
                onClick={() => scrollTo(0)}
              >
                {heading}
              </HeadingLink>
            ) : (
              <HeadingLink
                isSecondary={isSecondary}
                isCurrent={isCurrent}
                href={path}
              >
                {heading}
              </HeadingLink>
            )}
            {isCurrent && (
              <SubLinkContainer>
                {links.map((link, index) => {
                  const { text, path } = link;

                  return (
                    <SubLink
                      key={index}
                      onClick={() => {
                        setCurrentLocation(path);
                        scrollTo(index + 1);
                      }}
                      isCurrent={path === currentLocation}
                      isSelected={true}
                    >
                      {text}
                    </SubLink>
                  );
                })}
              </SubLinkContainer>
            )}
          </div>
        );
      })}
    </MenuWrapper>
  );
};
