import React from 'react';
import { menuData } from '../../data/menuData';
import { useScroll } from '../Scroller';
import MenuSublinks from './MenuSublinks';
import { HeadingLink, MenuWrapper } from './styles';

export default function Menu({ isSecondary = false, setShowContactPage }) {
  const { scrollTo } = useScroll();

  return (
    <MenuWrapper isSecondary={isSecondary}>
      {menuData.map((el, index) => {
        const { heading, path, links, secondary } = el;
        const isCurrent = isSecondary === secondary;
        const isContact = heading.toLowerCase() === 'contact';

        return (
          <div key={index}>
            {isContact ? (
              <HeadingLink
                as="button"
                isSecondary={isSecondary}
                isCurrent={isCurrent}
                onClick={() => setShowContactPage(true)}
              >
                Contact
              </HeadingLink>
            ) : (
              <HeadingLink
                isSecondary={isSecondary}
                isCurrent={isCurrent}
                href={path}
              >
                {heading}
              </HeadingLink>
            )}
            {isCurrent && (
              <MenuSublinks
                links={links}
                scrollTo={scrollTo}
                isSecondary={isSecondary}
              />
            )}
          </div>
        );
      })}
    </MenuWrapper>
  );
}
