import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useWindowWidth } from '../../context/WindowWidthContext';
import './styles.css';
import ReverseIcon from './reverse-icon.png';
import ReverseIconFront from './reverse-icon-front.png';

const margins = [0, 100];
const heights = [
  0,
  127,
  134,
  129,
  117,
  115,
  138,
  131,
  127,
  105,
  117,
  88,
  139,
  117,
  100,
  108,
  129,
];

export default function ReversableSlideCard({ data, index, setSelectedCard }) {
  const [showFront, setShowFront] = useState(true);
  const { width } = useWindowWidth();

  const imageHeight = heights.length >= index ? heights[index] : 100;
  const mobileHeight = heights.length >= index ? heights[index + 1] : 100;

  // whenever width changes between two card styles, reset the card state
  useEffect(() => {
    if (width <= 640) {
      setShowFront(true);
    } else {
      setSelectedCard(null);
    }
  }, [width]);

  const handleClick = () => {
    const el = document.getElementsByClassName('card');
    setShowFront(x => !x);

    if (el && el[index - 1]) {
      el[index - 1].classList.toggle('clicked');
    }
  };

  return (
    <Wrapper isFront={showFront} marginTop={margins[index % margins.length]}>
      {width > 640 ? (
        <div className="card" onClick={() => handleClick()}>
          <div className="content">
            <div className="front">
              <ImageContainer>
                <Image height={imageHeight} src={data.front.image.src} />
              </ImageContainer>
              <h3>{data.front.heading}</h3>
              <div className="icon">
                <img src={ReverseIconFront} />
              </div>{' '}
            </div>
            <div className="back">
              <p>{data.back.text}</p>
              <div className="icon">
                <img src={ReverseIcon} />
              </div>{' '}
            </div>
          </div>
        </div>
      ) : (
        <div onClick={() => setSelectedCard(data)}>
          <ImageContainer>
            <Image height={mobileHeight} src={data.front.image.src} />
          </ImageContainer>
          <h3>{data.front.heading}</h3>
          <div className="icon">
            <img src={showFront ? ReverseIconFront : ReverseIcon} />
          </div>
        </div>
      )}
    </Wrapper>
  );
}

const ImageContainer = styled.div`
  height: 164px;
  display: flex;
  align-items: center;
`;
const Image = styled.img`
  height: ${props => `${props.height}px`};
  margin-inline: auto;
  margin-bottom: 38px;
`;

const Wrapper = styled.div`
  background-color: ${props => (props.isFront ? '#fff' : '#91004B')};
  color: ${props => (!props.isFront ? '#fff' : '#91004B')};
  padding: 40px 12px;
  border-radius: 10px;
  position: relative;

  h3 {
    font-family: 'Lato';
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    margin-inline: auto;
    max-width: 224px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }

  .icon {
    position: absolute;
    bottom: 16px;
    right: 16px;
    background: none;
    border: none;
    height: 24px;

    img {
      height: 24px;
    }
  }

  padding-bottom: 48px;
  @media screen and (min-width: 640px) {
    background-color: transparent;
    margin-top: ${props => `${props.marginTop}px`};
    height: 340px;
    padding: ${props => (props.isFront ? '30px 22px' : '32px 24px')};
  }
`;
