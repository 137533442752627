import { useState, useEffect } from 'react';

export default function useShowHamburger() {
  const [showHamburger, setShowHamburger] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // check if scroll position is further down than the first (hero) section on the page
      // if so, display the hamburger menu icon; if not, don't display it because there is already a menu on the hero
      const checkScrollPosition = () => {
        const section = document.getElementsByTagName('section');

        if (section && section[0]) {
          const height = section[0].clientHeight;

          if (window.scrollY > height) {
            setShowHamburger(true);
          } else {
            setShowHamburger(false);
          }
        }
      };

      window.addEventListener('scroll', checkScrollPosition);

      return () => window.removeEventListener('scroll', checkScrollPosition);
    }
  }, []);

  return { showHamburger };
}
