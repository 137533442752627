import styled from 'styled-components';

export const StyledJobSlideWrapper = styled.div`
  padding-right: 10px;
  height: 207px;

  a {
    text-decoration: none;
  }

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    padding-right: 20px;
  }

  @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) and (orientation: landscape) {
    /* height: 160px; */
  }

  @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
    height: 239px;
  }
`;

export const StyledJobSlideContainer = styled.div`
  background-color: ${props => props.theme.white};
  color: ${props => props.theme.black};
  border-radius: 10px;
  padding: 29px 19px;
  height: 100%;
  width: 75vw;

  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
  transition: all 0.65s ease-out;
  transition-delay: 0.1s;
  position: relative;
  bottom: 0;

  display: -ms-grid;
  -ms-grid-rows: 35% 40% 20%;
  -ms-grid-columns: 1fr;

  grid-template-rows: 55% 25% 20%;
  grid-template-columns: 1fr;
  display: grid;

  :hover {
    box-shadow: 0 12px 20px 0 rgba(32, 32, 32, 0.4);
    transform: translate(0, -6px);
  }

  h4 {
    -ms-grid-column: 1;
    grid-column-start: 1;

    font-family: ${props => props.theme.bodyFont};

    -ms-grid-row: 1;
    grid-row-start: 1;

    margin-bottom: 0;
    font-weight: 600;
    font-size: 18px;
    margin-top: 0;
  }

  h5 {
    -ms-grid-row: 2;
    grid-row-start: 2;
  }

  h6 {
    -ms-grid-row: 3;
    grid-row-start: 3;
  }

  h5,
  h6 {
    font-family: ${props => props.theme.bodyFont};
    margin-bottom: 0;
    margin-top: 0;
    font-size: 14px;
  }

  a {
    color: ${props => props.theme.black};
    text-decoration: none;
    font-size: 14px;
  }

  /* TODO: extend the actual component */
  [class^='ArrowButton__StyledLottieContainer'] {
    display: inline-block;
    position: relative;
    bottom: -9px;
    left: 20px;
  }

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    width: 40vw;
  }

  @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
    width: 35vw;

    &:first-of-type {
      margin-left: 7.5;
    }

    h4 {
      font-size: 24px;
    }

    h5,
    h6 {
      font-size: 16px;
    }

    a {
      font-size: 1em;
    }
  }

  @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
    padding: 40px 45px;
    width: 26vw;

    /* h4 {
      font-size: calc(100vw * 0.012);
    }

    h5,
    h6 {
      font-size: calc(100vw * 0.01);
    }

    a {
      font-size: calc(100vw * 0.009);
    } */
  }
`;

export const StyledLittleDot = styled.div`
  background-color: ${props => props.theme.primaryCherry};
  display: inline-block;
  border-radius: 50%;
  margin: 0 10px 2px;
  height: 5px;
  width: 5px;
`;

export const StyledMoreInfoIconWrapper = styled.div`
  -ms-grid-row: 3;
  grid-row-start: 3;
  height: 28px;

  max-width: 130px;
  min-width: 80px;
  width: 24vw;

  /* @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-top: -10%;
  } */

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    width: 11vw;
  }

  @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
    width: 7vw;
  }

  @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
    width: 6vw;
  }
`;

// import styled from 'styled-components';

// export const StyledJobSlideWrapper = styled.div`
//   /* padding-right: 10px; */
//   /* height: 207px; */
//   height: 382px;
//   width: 240px;
//   margin-right: 24px;

//   iframe {
//     border: none;
//     height: 100%;
//     width: 100%;
//   }

//   a {
//     text-decoration: none;
//   }

//   @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
//     padding-right: 20px;
//     height: 695px;
//     width: 452px;
//   }

//   @media screen and (min-width: ${props =>
//       props.theme.minWidthLaptop}px) and (orientation: landscape) {
//     height: 160px;
//     height: 695px;
//     width: 542px;
//   }

//   @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
//     /* height: 239px;
//     height: 695px;
//     width: 542px; */
//   }
// `;

// export const StyledJobSlideContainer = styled.div`
//   background-color: ${props => props.theme.white};
//   color: ${props => props.theme.black};
//   border-radius: 10px;
//   padding: 29px 19px;
//   height: 100%;
//   width: 75vw;

//   box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
//   transition: all 0.65s ease-out;
//   transition-delay: 0.1s;
//   position: relative;
//   bottom: 0;

//   display: -ms-grid;
//   -ms-grid-rows: 35% 40% 20%;
//   -ms-grid-columns: 1fr;

//   grid-template-rows: 35% 45% 20%;
//   grid-template-columns: 1fr;
//   display: grid;

//   :hover {
//     box-shadow: 0 12px 20px 0 rgba(32, 32, 32, 0.4);
//     transform: translate(0, -6px);
//   }

//   h4 {
//     -ms-grid-column: 1;
//     grid-column-start: 1;

//     font-family: ${props => props.theme.bodyFont};

//     -ms-grid-row: 1;
//     grid-row-start: 1;

//     margin-bottom: 0;
//     font-weight: 600;
//     font-size: 18px;
//     margin-top: 0;
//   }

//   h5 {
//     -ms-grid-row: 2;
//     grid-row-start: 2;
//   }

//   h6 {
//     -ms-grid-row: 3;
//     grid-row-start: 3;
//   }

//   h5,
//   h6 {
//     font-family: ${props => props.theme.bodyFont};
//     margin-bottom: 0;
//     margin-top: 0;
//     font-size: 14px;
//   }

//   a {
//     color: ${props => props.theme.black};
//     text-decoration: none;
//     font-size: 14px;
//   }

//   /* TODO: extend the actual component */
//   [class^='ArrowButton__StyledLottieContainer'] {
//     display: inline-block;
//     position: relative;
//     bottom: -9px;
//     left: 20px;
//   }

//   @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
//     width: 40vw;
//   }

//   @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
//     width: 35vw;

//     &:first-of-type {
//       margin-left: 7.5;
//     }

//     h4 {
//       font-size: 24px;
//     }

//     h5,
//     h6 {
//       font-size: 16px;
//     }

//     a {
//       font-size: 1em;
//     }
//   }

//   @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
//     padding: 40px 45px;
//     width: 26vw;

//     /* h4 {
//       font-size: calc(100vw * 0.012);
//     }

//     h5,
//     h6 {
//       font-size: calc(100vw * 0.01);
//     }

//     a {
//       font-size: calc(100vw * 0.009);
//     } */
//   }
// `;

// export const StyledLittleDot = styled.div`
//   background-color: ${props => props.theme.primaryCherry};
//   display: inline-block;
//   border-radius: 50%;
//   margin: 0 10px 2px;
//   height: 5px;
//   width: 5px;
// `;

// export const StyledMoreInfoIconWrapper = styled.div`
//   -ms-grid-row: 3;
//   grid-row-start: 3;
//   height: 28px;

//   max-width: 130px;
//   min-width: 80px;
//   width: 24vw;

//   /* @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
//     margin-top: -10%;
//   } */

//   @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
//     width: 11vw;
//   }

//   @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
//     width: 7vw;
//   }

//   @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
//     width: 6vw;
//   }
// `;
