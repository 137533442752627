import styled from 'styled-components';

export const StyledCarouselSlideContainer = styled.div`
  ${props =>
    props.viewName === 'middle-carousel' && props.type === 'heading'
      ? `justify-content: center;`
      : ``};
  /* padding: ${props =>
    props.viewName === 'middle-carousel' && props.type !== 'heading'
      ? `20px`
      : `0`}; */
      padding: ${props => props.viewName !== 'benefits' && '20px'};
  background-color: ${props => props.type !== 'heading' && '#FFF'};
  cursor: ${props => props.type !== 'heading' && ``};
  width: calc(${props => props.width} * 300px);
  width: ${props => props.viewName == 'benefits' && `${22}px`}
  /* width: ${props =>
    props.viewName !== 'benefits'
      ? `${calc(props => props.width * 300`px`)}`
      : `${calc(props => (props.width - 40) * 300`px`)}`};; */
  min-width: 230px;
  /* flex-direction: column; */
  border-radius: 10px;
  /* display: flex; */
  margin: 12px;
  /* height: ${props => props.viewName === 'awards' && '382px'}; */
  /* background-color: ${props => props.isAward && '#fff'}; */
    background: transparent;
    background-color: ${props =>
      props.type !== 'heading' && props.viewName === 'services' && '#FFF'};
  /* ${props =>
    props.viewName === 'middle-carousel' ? 'height: 55vh;' : ''}; */

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    /* ${props =>
      props.viewName === 'middle-carousel' ? 'height: 50vh;' : ''}; */
    width: calc(${props => props.width} * 400px);
    max-width: 50vw;
    width: ${props => props.viewName == 'benefits' && `${282}px`};
    width: ${props => props.isBenefitsHeading && `360px`}
  }

  @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
    /* ${props =>
      props.viewName === 'middle-carousel' ? 'height: 60vh;' : ''}; */
      width: calc(${props => props.width} * 520px);
      width: ${props => props.viewName == 'benefits' && `${282}px`};
      width: ${props => props.isBenefitsHeading && `383px`}
  }
`;

export const StyledAwardSlideContainer = styled(StyledCarouselSlideContainer)`
  background-color: #ffffff;
  height: 302px;
  padding: 56px 24px 34px;
  margin-top: 0px;

  p {
    font-size: 18px;
    color: #3d3f46;
    line-height: 24px;
  }
  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    height: 382px;
    margin-top: ${props => `${props.marginTop}px`};
  }
  @media screen and (min-width: 768px) {
    padding-inline: 20px;

    p {
      font-size: 24px;
      line-height: 29px;
    }
  }
`;

export const StyledCsrReelSlideContainer = styled(StyledCarouselSlideContainer)`
  width: unset;

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    width: unset;
    max-width: unset;
  }

  @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
    width: unset;
    /* width: 50%; */
  }
`;

export const StyledInstagramIcon = styled.div`
  pointer-events: none;
  position: relative;
  bottom: 46px;
  height: 35px;
  width: 35px;
  left: 11px;
`;

export const StyledCarouselImageContainer = styled.div`
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease-out;
  transition-delay: 0.1s;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  border: none;
  margin-left: -20px;
  margin-right: -20px;

  img {
    transition: transform 3s ease-out;
    max-height: 100%;
    max-width: 100%;
  }

  &:hover {
    box-shadow: 0 12px 20px 0 rgba(32, 32, 32, 0.2);
    transform: translate(0, -4px);

    img {
      transform: scale(1.06);
    }
  }
`;

export const StyledCsrReelImageContainer = styled(StyledCarouselImageContainer)`
  box-shadow: none;
  border-radius: 0px;

  img {
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
    border-radius: 10px;
  }

  &:hover {
    box-shadow: none;

    img {
      box-shadow: 0 12px 20px 0 rgba(32, 32, 32, 0.2);

      /* transform: scale(1.06); */
    }
  }
`;

export const StyledIconSlideHeading = styled.h4`
  color: ${props => props.theme.primaryCherry};
  margin: 10px auto 0;
  font-size: ${props => (props.viewName === 'awards' ? '48px' : '15.3px')};
  font-weight: 600;
  font-family: ${props => props.theme.bodyFont};
  text-align: center;

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    line-height: 1.54;
    font-size: 16.7px;
    font-size: ${props => (props.viewName === 'awards' ? '48px' : '16.7')};
  }

  @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
    line-height: 1.5;
    font-size: 24px;
    font-size: ${props => (props.viewName === 'awards' ? '48px' : '24px')};
  }

  /* @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
    font-size: calc(100vw * 0.016);
    line-height: 0.96;
  } */
`;

export const StyledIconSlideCopy = styled.p`
  white-space: pre-wrap;
  line-height: 1.43;
  font-size: 14px;
  margin: ${props => (props.windowHeight >= 700 ? '45px' : '15px')} auto;
  margin-bottom: 0;

  line-height: 1.43;
  font-size: 14px;

  @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
    line-height: 1.5;
    font-size: 16px;
  }

  @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
    font-size: 1.6em;
  }

  /* @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
  }

  @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
    line-height: 1.5;
    font-size: 16px;
  } */

  /* @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
    font-size: calc(100vw * 0.011);
    line-height: 1.52;
  } */
`;
