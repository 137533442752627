import React, { useState, useEffect } from 'react';
import { StaticQuery, graphql, useStaticQuery } from 'gatsby';
import { useSpring, config, animated } from 'react-spring';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';

import {
  StyledAnimatedHero,
  StyledBackground,
  Styledlogo,
  // StyledDownwardScrollingArrow,
  StyledHiddenH1,
  Content,
  Strapline,
  HeroButton,
  StyledCultureHero,
  StyledLogoAlt,
} from './styles';
// import theme from '../Layout/theme';
import { useWindowWidth } from '../../context/WindowWidthContext';
import { fileSuffixFromWidth } from '../../helpers';

import CherryLogo from '../../images/cherryLogo.svg';
import CherryLogoAlt from '../../images/cherry-logo-alt.svg';
import { useScroll } from '../Scroller';
import { navigate } from '@reach/router';

// import Hero_arrow from '../../images/lottie/Hero_arrow.json';

const imageToBuzzWordLookup = {
  cactus: 'blossom',
  fish: 'evolve',
  duck: 'grow',
};

export default function Hero({
  backgroundDelay = 0,
  logoDelay = 0,
  zoomDelay = 0,
  isCulturePage = false,
}) {
  let heroImages = ['fish', 'cactus', 'duck'];
  const [suffix, setSuffix] = useState(null);
  const { width } = useWindowWidth();
  const { scrollTo } = useScroll();

  // console.log(window.localStorage.getItem("imageOrder"));
  let orderValue =
    typeof window !== 'undefined'
      ? Number(window.localStorage.getItem('imageOrder'))
      : 0;

  useEffect(() => {
    if (window.localStorage.getItem('imageOrder') === null) {
      window.localStorage.setItem('imageOrder', 0);
    } else if (window.localStorage.getItem('imageOrder') >= 2) {
      window.localStorage.setItem('imageOrder', 0);
    } else {
      window.localStorage.setItem('imageOrder', (orderValue += 1));
    }
  }, []);

  const logoStyleProps = useSpring({
    from: { transform: `translate3d(0, -150%, 0)` },
    to: { transform: `translate3d(0, 0%, 0)` },
    config: config.slow,
    delay: logoDelay,
  });

  const heroStyleProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { mass: 1, tension: 90, friction: 20 },
    delay: backgroundDelay,
  });

  const wordFade = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { mass: 1, tension: 90, friction: 20 },
  });

  const heroZoom = useSpring({
    from: { transform: 'scale(1)' },
    to: { transform: 'scale(1.1)' },
    config: { mass: 1, tension: 120, friction: 240 },
    delay: zoomDelay,
  });

  // const lottieHeroArrowDefaultOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: Hero_arrow,
  //   rendererSettings: {
  //     preserveAspectRatio: 'xMidYMid meet',
  //   },
  // };

  // const [ref, inView] = useInView({
  //   threshold: 0.85,
  // });

  // React.useEffect(() => {
  //   if (inView === true) {
  //     props.setActiveView(inView, 'hero');
  //     //window.history.pushState('', '/', window.location.pathname);
  //     window.location.hash = '';
  //   }
  // }, [inView]);

  const data = useStaticQuery(graphql`
    query {
      cactus_mobile: file(relativePath: { eq: "hero/cactus_mobile_2x.png" }) {
        childImageSharp {
          fixed(width: 450, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      cactus_tablet: file(relativePath: { eq: "hero/cactus_tablet.png" }) {
        childImageSharp {
          fixed(width: 768, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      cactus_laptop: file(relativePath: { eq: "hero/cactus_laptop.png" }) {
        childImageSharp {
          fixed(width: 1440, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      cactus_desktop: file(relativePath: { eq: "hero/cactus_desktop.png" }) {
        childImageSharp {
          fixed(width: 2558, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      duck_mobile: file(relativePath: { eq: "hero/duck_mobile_2x.png" }) {
        childImageSharp {
          fixed(width: 450, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      duck_tablet: file(relativePath: { eq: "hero/duck_tablet.png" }) {
        childImageSharp {
          fixed(width: 768, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      duck_laptop: file(relativePath: { eq: "hero/duck_laptop.png" }) {
        childImageSharp {
          fixed(width: 1440, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      duck_desktop: file(relativePath: { eq: "hero/duck_desktop.png" }) {
        childImageSharp {
          fixed(width: 2558, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      fish_mobile: file(relativePath: { eq: "hero/fish_mobile_2x.png" }) {
        childImageSharp {
          fixed(width: 450, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      fish_tablet: file(relativePath: { eq: "hero/fish_tablet.png" }) {
        childImageSharp {
          fixed(width: 768, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      fish_laptop: file(relativePath: { eq: "hero/fish_laptop.png" }) {
        childImageSharp {
          fixed(width: 1440, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      fish_desktop: file(relativePath: { eq: "hero/fish_desktop.png" }) {
        childImageSharp {
          fixed(width: 2558, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  useEffect(() => {
    setSuffix(fileSuffixFromWidth(width));
  }, [width]);

  if (isCulturePage) {
    return (
      <StyledCultureHero>
        <StyledLogoAlt
          style={logoStyleProps}
          onClick={() => {
            navigate('/');
          }}
        >
          <CherryLogoAlt />
        </StyledLogoAlt>
        <Content>
          <Strapline>
            <strong>
              <em>Hello, we are Cherry -</em>
            </strong>
            <br />a full-service healthcare creative agency that grows brands
            and careers.
          </Strapline>
          <HeroButton onClick={() => scrollTo(6)}>View open roles</HeroButton>
        </Content>
      </StyledCultureHero>
    );
  }

  return (
    <StyledAnimatedHero>
      <StyledHiddenH1>Cherry Advertising Homepage</StyledHiddenH1>
      <StyledBackground
        style={{ ...heroStyleProps, ...heroZoom }}
        bgurl={
          suffix
            ? data[heroImages[orderValue] + suffix].childImageSharp.fixed.src
            : null
        }
      />
      <Styledlogo
        style={logoStyleProps}
        onClick={() => {
          scrollTo(0);
        }}
      >
        <CherryLogo />
      </Styledlogo>
      <animated.h2 style={wordFade}>
        {imageToBuzzWordLookup[heroImages[orderValue]]}
      </animated.h2>
    </StyledAnimatedHero>
  );
}

Hero.propTypes = {
  setActiveView: PropTypes.func,
  logoDelay: PropTypes.number,
  zoomDelay: PropTypes.number,
  delay: PropTypes.number,
};
