export const jobData = [
  {
    heading: 'Junior Digital Artworker',
    link: 'https://www.linkedin.com/jobs/view/3083829573/',
  },
  {
    heading: 'Junior Account Executive',
    link: 'https://www.linkedin.com/jobs/view/3116533538/',
  },
  {
    heading: 'Traffic Manager',
    link: 'https://www.linkedin.com/jobs/view/3090606179/',
  },
];

import img1 from '../components/CSRReel/image-1.png';
import img2 from '../components/CSRReel/image-2.png';
import img3 from '../components/CSRReel/image-3.png';
import img4 from '../components/CSRReel/image-4.png';
import img5 from '../components/CSRReel/image-5.png';
import img6 from '../components/CSRReel/image-6.png';
import img7 from '../components/CSRReel/image-7.png';
import img8 from '../components/CSRReel/image-8.png';
import img9 from '../components/CSRReel/image-9.png';
import img10 from '../components/CSRReel/image-10.png';
import img11 from '../components/CSRReel/image-11.png';
import img12 from '../components/CSRReel/image-12.png';

const images = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
];

export const csrReel = {
  carouselSlides: [
    ...images.map(img => {
      return {
        image: { fluid: { src: img }, description: '' },
        iconJson: { file: { url: null } },
      };
    }),
  ],
  contentful_id: null,
  copy: {
    copy: `Our staff don't wear capes but twice a year swap their laptops for an apron or gardening gloves. We believe in making a real difference to the wider community, so we offer our staff paid leave to do so.<br/><br/> Our company-run volunteer days have included lunches for vulnerable adults as well as litter picking and gardening. In addition, we regularly take part in fundraisers for charitable causes.
    `,
  },
  heading: 'Become a community superhero',
};
