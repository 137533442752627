export const menuData = [
  {
    heading: 'Home',
    path: '/',
    links: ['About', 'Our work', 'Services', 'Careers', 'Location'],
    links: [
      { text: 'About', path: '#about' },
      { text: 'Our work', path: '#our-work' },
      { text: 'Services', path: '#services' },
      { text: 'Careers', path: '#careers' },
      { text: 'Location', path: '#location' },
    ],
    secondary: false,
  },
  {
    heading: 'Culture',
    path: '/culture',
    links: [
      { text: 'Awards', path: '#awards' },
      { text: 'Grow with us', path: '#grow' },
      { text: 'Community work', path: '#csr' },
      { text: 'Benefits', path: '#benefits' },
      { text: 'Sparks internship', path: '#sparks' },
      { text: 'Careers', path: '#careers' },
    ],
    secondary: true,
  },
  {
    heading: 'Contact',
    links: [],
  },
];
