import styled from 'styled-components';
import { animated } from 'react-spring';

export const StyledHiddenH1 = styled.h1`
  display: block;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  font-size: 2px;
`;

export const StyledAnimatedHero = styled(animated.div)`
  position: relative;
  height: 100vh;
  min-height: 568px;
  overflow: hidden;

  h2 {
    color: ${props => props.theme.primaryGrey};
    font-size: 40px;
    margin-top: 0;
    z-index: 1;
    padding-bottom: 15vh;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 500;
    /* @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      position: absolute;

      left: 45%;

      margin-top: 3%;
    }

    @supports (-ms-ime-align: auto) {
      position: absolute;
      z-index: 5;
      bottom: 0;

      left: 50%;
    } */
    @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
      font-size: 60px;
      padding-bottom: 25px;
    }

    /* Laptop */
    @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
      font-size: 60px;
    }

    /* Desktop */
    @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
      font-size: 6.5em;
    }
  }
`;

export const StyledCultureHero = styled(StyledAnimatedHero)`
  background: #91004b;
  position: relative;
  max-height: 481px;
  min-height: unset;

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    max-height: unset;
  }
`;

export const Styledlogo = styled(animated.div)`
  background-image: ${props => props.theme.staticTiltedGradient};
  border-radius: 0 0 10px 10px;
  justify-content: center;
  padding-left: 7px;
  align-items: center;
  position: absolute;
  left: 12px;
  cursor: pointer;
  display: flex;
  height: 65px;
  width: 81px;
  z-index: 2;
  top: 0;

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    height: 113px;
    width: 142px;
    left: 7.5%;
    padding-left: 10px;
  }

  @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
    height: 143px;
    width: 180px;
    padding-left: 14px;
  }

  @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
    width: 207px;
    height: 164px;
  }

  svg {
    z-index: 2;
    width: 65%;
  }
`;

export const StyledLogoAlt = styled(animated.div)`
  position: absolute;
  left: 12px;
  cursor: pointer;
  z-index: 2;
  top: 0;

  svg {
    height: 63px;
    width: 81px;
  }

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    height: 113px;
    width: 142px;
    left: 7.5%;
    padding-left: 10px;

    svg {
      height: 111px;
      width: 142px;
    }
  }

  @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
    height: 143px;
    width: 180px;
    padding-left: 14px;

    svg {
      height: 141px;
      width: 180px;
    }
  }

  @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
    width: 207px;
    height: 164px;
  }

  /* svg {
    z-index: 2;
    width: 65%;
  } */
}`;

export const StyledBackground = styled(animated.div)`
  position: absolute;

  width: 100%;
  height: 100%;

  background: url(${props => props.bgurl});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 1;
  transform-origin: center;
`;

export const StyledDownwardScrollingArrow = styled.div`
  justify-self: center;
  grid-area: 3/1/4/2;
  margin-top: 60px;
  height: 20px;
  width: 20px;
  z-index: 1;
  grid-row: 3;
  grid-column: 1;

  /* Tablet and greater widths in Portrait */
  @media screen and (min-width: ${props =>
      props.theme.minWidthTablet}px) and (orientation: portrait) {
    margin-top: 90px;
  }

  /* Tablet Landscape */
  @media screen and (min-width: 1000px) and (orientation: landscape) {
    margin-top: 80px;
  }

  /* Laptop */
  @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
    margin-top: 110px;
    height: 30px;
    width: 30px;
  }

  /* Desktop */
  @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
    margin-top: 160px;
    height: 43px;
    width: 43px;
  }
`;

export const Content = styled.div`
  max-width: 284px;
  color: #ffffff;
  margin-top: 256px;
  margin-left: 14px;
  position: absolute;
  bottom: 62px;

  @media screen and (min-width: 769px) {
    max-width: 740px;
    bottom: 96px;
  }
  @media screen and (min-width: 1440px) {
    margin-left: 120px;
    max-width: 792px;
    bottom: 100px;
    bottom: 76px;
  }
`;

export const Strapline = styled.h1`
  font-size: 26px;
  line-height: 33px;
  margin-bottom: 32px;
  font-weight: normal;
  font-family: none;
  max-width: 740px;

  @media screen and (min-width: 769px) {
    font-size: 48px;
    line-height: 61px;
  }
`;

export const HeroButton = styled.button`
  background-color: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 22px;
  color: #91004b;
  font-size: 14px;
  width: 180px;
  height: 44px;
  font-weight: 500;

  &:hover {
    cursor: pointer;
  }
`;
