import React from 'react';
import { useSpring } from 'react-spring';
import {
  LeftBox,
  RightBox,
  StyledText,
  StyledTitle,
  RightBoxMenu,
  MenuSection,
  MobileButtonContainer,
  MenuButton,
  SubMenuWrapper,
} from './styles';

import styled from 'styled-components';

export const MobileContact = styled.div`
  padding: 56px 18px 14px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  a {
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    color: #ffffff;
    text-decoration: none;
    margin-bottom: 24px;
    width: fit-content;
    letter-spacing: 1px;

    &:last-of-type {
      border-bottom: 1px solid white;
      padding-bottom: 4px;
    }
  }

  p {
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
  }

  hr {
    margin-block: 32px;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  }

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    display: none;
  }
`;

import Arrow from './arrow.png';
import { Button } from '../Layout';
import { useWindowWidth } from '../../context/WindowWidthContext';
import { Link, navigate } from '@reach/router';

export const ContactPopup = ({ animateNav }) => {
  const { width } = useWindowWidth();

  const springConfig = { mass: 1, tension: 180, friction: 14, clamp: true };

  const textAnimation = useSpring({
    config: springConfig,
    opacity: animateNav ? 1 : 0,
    x: animateNav ? 0 : -20,
  });

  const textAnimationDelayed = useSpring({
    config: springConfig,
    opacity: animateNav ? 1 : 0,
    delay: 350,
  });

  return (
    <div style={{ height: '100%' }}>
      {/* <MenuContent> */}
      <LeftBox></LeftBox>

      {width < 640 && (
        <>
          {/* <SubMenu /> */}
          <MobileContact>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Link to="/">HOME</Link>
              <Link to="/culture">CULTURE</Link>
              <a href="#">CONTACT</a>
            </div>
            <div>
              <p>
                Get in touch if you're looking for an{' '}
                <strong>
                  <em>agency that really looks after you and your brands</em>
                </strong>
                , or if you'd like to know what{' '}
                <strong>
                  <em>being part of our team</em>
                </strong>{' '}
                is like.
              </p>
              <hr />
              <MobileButtons textAnimationDelayed={textAnimationDelayed} />
            </div>
          </MobileContact>
        </>
      )}

      {width >= 640 && (
        <RightBox>
          <RightBoxMenu style={{ ...textAnimationDelayed }}>
            <MenuSection>
              <StyledTitle gridrow="1 / span 1">
                Work{' '}
                <strong>
                  <em>with</em>
                </strong>{' '}
                Cherry
              </StyledTitle>

              <StyledText gridrow="3 / span 1">
                If you're looking for an agency that really looks after you and
                your brands - get in touch.
              </StyledText>

              <div>
                <Button
                  gridrow="5 / span 1"
                  msgridrow="5"
                  msgridrowspan="1"
                  marginbottom="5%"
                  marginleft="0%"
                  innerText="I'm a client"
                  onClick={() =>
                    (window.location.href = 'mailto:info@cherrythinking.com')
                  }
                >
                  Get in touch
                </Button>
              </div>
            </MenuSection>
            <MenuSection>
              <StyledTitle gridrow="7 / span 1">
                Work{' '}
                <strong>
                  <em>at</em>
                </strong>{' '}
                Cherry
              </StyledTitle>

              <StyledText gridrow="9 / span 1">
                Find out what working as part of our team is like and apply for
                any open roles.
              </StyledText>
              <div>
                <Button
                  gridrow="11 / span 1"
                  marginbottom="5%"
                  marginleft="0%"
                  innerText="I'm prospective talent"
                  onClick={() => navigate('/culture')}
                >
                  Work at Cherry
                </Button>
              </div>
            </MenuSection>
          </RightBoxMenu>
        </RightBox>
      )}
      {/* </MenuContent> */}
    </div>
  );
};

const MobileButtons = ({ textAnimationDelayed }) => {
  return (
    <MobileButtonContainer>
      <div>
        <MenuButton
          marginleft="5.6%"
          innerText="Email"
          style={textAnimationDelayed}
          onClick={() =>
            (window.location.href = 'mailto:info@cherrythinking.com')
          }
        >
          Email
        </MenuButton>
      </div>
      <div>
        <MenuButton
          marginleft="5.6%"
          innerText="Call"
          style={textAnimationDelayed}
          onClick={() => (window.location.href = 'tel:+44-207-940-7310')}
        >
          Call
        </MenuButton>
      </div>
    </MobileButtonContainer>
  );
};

const SubMenu = () => {
  const handleClick = () => {
    window.location.href = '/culture';
  };

  return (
    <SubMenuWrapper>
      <hr />
      <button onClick={() => handleClick()}>
        <strong>Working at Cherry and open roles</strong>
        <img src={Arrow} alt="arrow" />
      </button>
      <hr />
      <p>Want to get in touch?</p>
    </SubMenuWrapper>
  );
};
