import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';

import theme from '../Layout/theme';
import ReadMoreIcon from '../../images/lottie/read_more.json';

import {
  StyledJobSlideWrapper,
  StyledJobSlideContainer,
  StyledLittleDot,
  StyledMoreInfoIconWrapper,
} from './styles';

export default function JobSlide({ width, slide }) {
  /**
   * START: Handle More Info icon animations
   */


  const [autoplay, setAutoplay] = React.useState(false);
  const [direction, setDirection] = React.useState(-1);

  function playForward() {
    if (width < theme.minWidthLaptop) return;
    setAutoplay(true);
    setDirection(1);
  }

  function playReverse() {
    if (width < theme.minWidthLaptop) return;
    setAutoplay(true);
    setDirection(-1);
  }

  const lottieMoreInfoOptions = {
    animationData: ReadMoreIcon,
    autoplay: false,
    direction: -1,
    loop: false,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid meet',
    },
  };
  // END: Handle More Info icon animations

  return (
    <StyledJobSlideWrapper>
      <a
        href={slide.absolute_url}
        target="_blank"
        rel="noopener"
      >
        <StyledJobSlideContainer>
          <h4>{slide.title}</h4>

          <h5>
            {slide.departments[0].name}
            <StyledLittleDot />
            {slide.offices[0].name}
          </h5>

          <h6>
            <StyledMoreInfoIconWrapper
              onMouseEnter={playForward}
              onMouseLeave={playReverse}
            >
              <Lottie
                options={{ ...lottieMoreInfoOptions, autoplay }}
                isClickToPauseDisabled={true}
                direction={direction}
                height={'100%'}
                width={'100%'}
              />
            </StyledMoreInfoIconWrapper>
          </h6>
        </StyledJobSlideContainer>
      </a>
    </StyledJobSlideWrapper>
  );
}

JobSlide.propTypes = {
  width: PropTypes.number.isRequired,
  slide: PropTypes.any.isRequired,
};
