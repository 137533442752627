import React, { useState } from 'react';
import Menu from '../Menu';
import Nav from '../Nav';

export default function Navigation({
  isCulturePage = false,
  isSecondary = false,
}) {
  const [showContactPage, setShowContactPage] = useState(false);
  const [showNav, setShowNav] = useState(false);

  return (
    <>
      <Nav
        isCulturePage={isCulturePage}
        showContactPage={showContactPage}
        setShowContactPage={setShowContactPage}
        showNav={showNav}
        setShowNav={setShowNav}
      />
      <Menu
        isSecondary={isSecondary}
        setShowContactPage={setShowContactPage}
        showNav={showNav}
        setShowNav={setShowNav}
      />
    </>
  );
}
