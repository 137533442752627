import styled from 'styled-components';

export const MenuWrapper = styled.div`
  display: none;
  position: absolute;
  right: 165px;
  top: 76px;
  z-index: 2;
  width: 120px;
  color: ${props =>
    !props.isSecondary ? '#3D3F46' : 'rgba(255, 255, 255, 0.65)'};
  font-size: 12px;
  font-family: 'Lato';

  @media screen and (min-width: 1440px) {
    display: block;
  }

  p {
    margin-bottom: 16px;
    letter-spacing: 0.5px;
  }

  button {
    border: none;
    background: none;
    padding: 0px;
    width: fit-content;
  }
`;
export const HeadingLink = styled.a`
  display: block;
  width: fit-content;
  color: ${props => (!props.isSecondary ? '#91004B' : 'white')};
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  letter-spacing: 1px;
  margin-bottom: 12px;
  text-transform: uppercase;
  text-decoration: ${props => (props.isCurrent ? 'underline' : 'none')};
  text-underline-offset: 4px;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: ${props =>
      !props.isSecondary ? '#91004B' : '#ffffff'}};
  }
`;

export const SubLink = styled.button`
  background: none;
  border: none;
  color: ${props =>
    !props.isSecondary ? '#3D3F46' : 'rgba(255, 255, 255, 0.65)'};
  padding: 0px;
  margin: 0px;
  display: block;
  margin-bottom: 16px;
  font-weight: 600;
  text-decoration: ${props => (props.isCurrent ? 'underline' : 'none')};
  text-underline-offset: 4px;

  &:hover {
    cursor: pointer;
    text-underline-offset: 4px;
    text-decoration: underline;
    text-decoration-color: ${props =>
      !props.isSecondary ? '#3d3f46' : ' rgba(255, 255, 255, 0.65)'}};
  }
`;
