import React from 'react';
import { throttle } from 'lodash';
import { StyledHamburger, StyledHamburgerWrapper } from './styles';
import useShowHamburger from '../../hooks/useShowHamburger';

function showMenuAndAnimate(toggle) {
  toggle();
}

let throttledFunc = throttle(showMenuAndAnimate, 1000, {
  leading: true,
  trailing: false,
});

const HamburgerMenu = ({
  animateNav,
  toggle,
  isCulturePage = false,
  isMenuOpen = false,
}) => {
  const { showHamburger } = useShowHamburger();

  return (
    <StyledHamburgerWrapper
      animateNav={animateNav}
      onClick={() => {
        throttledFunc(toggle);
      }}
      isCulturePage={isCulturePage}
      showHamburger={animateNav || (!animateNav && showHamburger)}
      isMenuOpen={isMenuOpen}
    >
      <StyledHamburger
        animateNav={animateNav}
        isCulturePage={isCulturePage}
        isMenuOpen={isMenuOpen}
        showHamburger={showHamburger}
        id="hamburger-menu"
      />
    </StyledHamburgerWrapper>
  );
};

export default HamburgerMenu;
