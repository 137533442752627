import React from "react";
import PropTypes from "prop-types";
import Lottie from "react-lottie";
import styled from "styled-components";

import theme from "../Layout/theme";

import Arrow_right from "../../images/lottie/Arrow_right.json";
import Arrow_left from "../../images/lottie/Arrow_left.json";

import Arrow_right_white from "../../images/lottie/Arrow_right_white.json";
import Arrow_left_white from "../../images/lottie/Arrow_left_white.json";

const StyledLottieContainer = styled.div`
  cursor: ${props => (props.disablePrevArrow ? "auto" : "pointer")};
  opacity: ${props => (props.disablePrevArrow ? 0 : 1)};
  transition: opacity 0.25s ease;
  display: inline-block;
  margin: 0 12px;
  width: 85px;

  @media (min-width: ${props => props.theme.minWidthLaptop}px) {
    &:not(:hover) {
      ${props => (props.color === "white" ? `` : `filter: grayscale(1) opacity(0.5);`)}
    }
  }

  @media (min-width: ${props => props.theme.minWidthDesktop}px) {
    width: 85px;
  }
`;

const lottieBaseOptions = {
  autoplay: false,
  direction: -1,
  loop: false,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid meet",
  },
};

const lottieArrowRightDefaultOptions = color => ({
  animationData: color === "white" ? Arrow_right_white : Arrow_right,
  ...lottieBaseOptions,
});

const lottieArrowLeftDefaultOptions = color => ({
  animationData: color === "white" ? Arrow_left_white : Arrow_left,
  ...lottieBaseOptions,
});

export default function ArrowButton(props) {
  const [autoplay, setAutoplay] = React.useState(false);
  const [direction, setDirection] = React.useState(-1);

  const options =
    props.arrowDirection === "left"
      ? lottieArrowLeftDefaultOptions(props.color)
      : lottieArrowRightDefaultOptions(props.color);

  function playForward() {
    if (props.width < theme.minWidthLaptop) return;
    setAutoplay(true);
    setDirection(1);
  }

  function playReverse() {
    if (props.width < theme.minWidthLaptop) return;
    setAutoplay(true);
    setDirection(-1);
  }

  return (
    /** call onClick afer animation clean */
    <StyledLottieContainer
      disablePrevArrow={props.disablePrevArrow}
      arrowDirection={props.arrowDirection}
      onMouseEnter={playForward}
      onMouseLeave={playReverse}
      onClick={props.onClick}
      width={props.width}
      color={props.color}
    >
      <Lottie
        isClickToPauseDisabled={true}
        options={{ ...options, autoplay }}
        direction={direction}
        height={"100%"}
        width={"100%"}
      />
    </StyledLottieContainer>
  );
}

ArrowButton.propTypes = {
  arrowDirection: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  disablePrevArrow: PropTypes.bool,
  onClick: PropTypes.func,
  color: PropTypes.string,
};
