import styled from 'styled-components';
import { StyledCarouselSlideContainer } from '../CarouselSlide/styles';

/**
 * START: Tablet-only styles for Heading and Copy
 */
export const StyledCarouselHeadingContainer = styled.div`
  justify-self: center;
  align-self: flex-start;

  margin-bottom: 30px;
  padding: 0 12px;

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    width: 71%;
    padding-left: 11%;
  }
`;

export const StyledCarouselHeading = styled.h2`
  color: ${props => props.theme.primaryCherry};
  font-weight: 100;
  font-size: 32px;
  /* font-size: ${props => (props.viewName === 'awards' ? '48px' : '32px')}; */
  margin-top: 0;
  margin-bottom: 24px;
  line-height: 40px;
  

  @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
    font-size: 48px;
    /* padding: 0 80px; */
    margin: 26px 0;
    line-height: 56px;
    max-width: 544px;
  }

  @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
    font-size: 60px;
    line-height: 56px;
    max-width: 750px;
    /* font-size: ${props => (props.viewName === 'awards' ? '48px' : '60px')} */
  }

  

  /* @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
    font-size: calc(100vw * 0.032);
  } */
`;

// export const StyledAwardHeading = styled(StyledCarouselHeading)`
//   font-size: 65px;
//   font-weight: 400;
//   text-align: left;

//   span {
//     font-size: 40px;
//   }
//   @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
//     font-size: 90px;

//     span {
//       font-size: 40px;
//     }

//     margin-top: 6px;
//     margin-bottom: 0px;
//   }
// `;
export const StyledAwardHeading = styled.div`

h2, h3 {
  color: ${props => props.theme.primaryCherry};
    font-weight: 100;
    font-size: 32px;
    
}

  h2 {
    color: ${props => props.theme.primaryCherry};
    font-weight: 100;
    font-size: 32px;
    /* font-size: ${props =>
      props.viewName === 'awards' ? '48px' : '32px'}; */
    /* margin-top: 0;
    margin-bottom: 24px; */
    margin-bottom: 0px;
    line-height: 40px;

    @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
      font-size: 60px;
    }


    font-size: 65px;
    font-weight: 400;
    text-align: left;

    span {
      font-size: 40px;
    }
    @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
      margin: 26px 0;
      font-size: 90px;

      span {
        font-size: 40px;
      }

      margin-bottom: 0px;
    }
  }

  h3 {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    letter-spacing: 0.5px;

    em {
      font-family: "LoraItalic";
    }

    @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
      font-family: 'Lora';
      font-size: 24px;
      line-height: 31px;
    }
  }
`;

export const StyledCarouselCopy = styled.div`
  line-height: 1.43;
  font-size: 14px;

  @media screen and (min-width: 768px) {
    max-width: 544px;
  }
  @media screen and (min-width: 1440px) {
    /* max-width: 384px; */
  }

  @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
    line-height: 1.5;
    font-size: 16px;
    /* padding: 0 80px; */
  }

  @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
    font-size: 1.6em;
  }

  p:not(:last-child) {
    margin-bottom: 15px;
  }

  /* @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
    font-size: calc(100vw * 0.011);
    line-height: 1.52;
  } */
`;

export const StyledCSRCopy = styled(StyledCarouselCopy)`
  @media screen and (min-width: 768px) {
    max-width: 487px;
  }
`;

export const StyledAwardCopy = styled(StyledCarouselCopy)`
  font-weight: 500;
`;

export const StyledCarouselHeadingImages = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 24px;

  img {
    height: 64px;
    width: 64px;
  }

  @media screen and (min-width: 768px) {
    img {
      height: 100px;
      width: 100px;
    }
  }
  @media screen and (min-width: 1440px) {
    margin-top: 54px;
  }
`;
// END: Tablet-only styles for Heading and Copy

export const StyledCarouselSlideWrapper = styled.div`
  grid-area: 2/1/3/2;

  .slick-track {
    align-items: stretch;
    flex-direction: row;
    flex-wrap: nowrap;
    display: flex;
  }

  .slick-slider,
  .slick-list,
  .slick-track {
    height: 100%;
  }

  .slick-slide {
    /* align-self: center; */
    display: flex;
    height: auto;
    /* 
    -ms-grid-row-align: center; */
  }

  /* @media screen and (-ms-high-contrast: active) and (-ms-high-contrast: none) {
    .slick-slide {
      height: 100%;
    }
  } */
`;

const widths = [486, 282, 455, 282, 282, 282, 455, 282, 282, 455, 282, 282];

export const StyledCsrReelSlideWrapper = styled(StyledCarouselSlideWrapper)`
  @media screen and (max-width: ${props => props.theme.minWidthTablet - 1}px) {
    .slick-track {
      align-items: ${props =>
        props.viewName === 'csrReel' && 'center !important'};
      -moz-align-items: ${props =>
        props.viewName === 'csrReel' && 'center !important'};
    }
  }

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    ${props =>
      props.viewName === 'csrReel' &&
      widths.map((width, index) => {
        return `.slick-slide:nth-child(${index + 2}){ width: ${width}px; }`;
      })}
  }
`;

export const StyledCarouselArrowWrapper = styled.div`
  justify-self: center;
  align-self: center;
  margin-top: 50px;
  /* grid-area: 3/1/4/2;
  grid-row: 3;
  grid-column: 1; */
  /* -ms-grid-column-align: center;
  -ms-grid-row-align: center;
  -ms-grid-row: 3; */

  /* -ms-grid-row-span: 1;
  -ms-grid-column: 1; */

  /* -ms-grid-column-span: 1; */
`;

export const StyledCarouselPageWrapper = styled.div`
  /* min-height: 100vh; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* padding: 50px 0; */
  padding: ${({ viewName }) => (viewName == 'benefits' ? '0px' : '50px 0px')};
  height: ${props => props.viewName == 'benefits' && '800px'};

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    .slick-track:first-child {
      margin-left: 7.5%;
    }

    padding: ${({ viewName }) => viewName == 'awards' && '110px 0px 50px'};
    .slick-slide:first-child {
      margin-left: 0;
      margin-top: ${props => props.viewName === 'benefits' && `${100}px;`};
      align-self: ${props => props.viewName !== 'benefits' && `center`};
      padding-right: 50px;

      ${StyledCarouselSlideContainer} {
        padding: 0;
        margin: 0;
      }
    }
  }

  @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
    .slick-slide:first-child {
      padding-right: 80px;
    }
  }
`;
