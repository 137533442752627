import React from 'react';
import { SubLink } from './styles';

export default function MenuSublinks({
  links = [],
  scrollTo,
  isSecondary = false,
}) {
  return (
    <div>
      {links.map((link, index) => {
        const { text } = link;

        return (
          <SubLink
            key={index}
            onClick={() => {
              scrollTo(index + 1);
            }}
            isSecondary={isSecondary}
          >
            {text}
          </SubLink>
        );
      })}
    </div>
  );
}
