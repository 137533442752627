import styled from 'styled-components';
import { animated } from 'react-spring';
import { Button } from '../Layout';

const animationTime = '0.5s';

export const NavContactWrapper = styled(animated.div)`
  background-image: ${props => props.theme.staticTiltedGradient};
  z-index: 8;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  overflow-x: hidden;

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    display: flex;
    flex-direction: column;
    padding-top: 185px;
    padding-left: 113px;
  }
  @media screen and (min-width: 1440px) {
    padding-top: 139px;
    padding-left: 222px;
  }
`;
export const NavMenuWrapper = styled(animated.div)`
  background-image: ${props => props.theme.staticTiltedGradient};
  z-index: 8;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  overflow-x: hidden;
  /* overflow-y: hidden; */
  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    display: flex;
    flex-direction: column;
    /* padding: 50px 0; */
  }

  @media screen and (min-width: 1440px) {
    background-image: none;
  }
`;

export const MenuContent = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    flex-direction: row;
    min-height: 0;
    margin: auto;
  }

  @media screen and (min-width: 1440px) {
    position: absolute;
    right: 122px;
    top: 150px;
    width: 180px;
    /* background: beige; */
    /* background-color: #ffffff; */
    /* background-color: rgba(255, 255, 255, 0.75); */
    height: unset;
    z-index: 2;
    /* backdrop-filter: blur(20px); */
  }
`;

export const StyledNavButton = styled.div`
  width: 30px;
  height: 30px;

  border-radius: 50%;

  background: ${props => props.theme.primaryCherry};

  background: black;

  position: absolute;
  top: 5%;
  right: 7%;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;

  z-index: 9;

  overflow: hidden;

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    width: 45px;
    height: 45px;
  }
`;

export const StyledNavLine = styled(animated.div)`
  width: 15px;
  height: 2px;
  background: white;
  margin-top: ${props => props.margin};
  position: absolute;
  top: ${props => props.top};
`;

// export const NavMenu = styled(animated.div)`
//   background-image: ${props => props.theme.staticTiltedGradient};
//   z-index: 8;
//   position: fixed;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   overflow-y: auto;
//   overflow-x: hidden;

//   @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
//     display: flex;
//     flex-direction: column;
//     padding: 50px 0;
//   }
// `;

export const NavMenuItem = styled(animated.p)`
  font-size: 26px;
  font-weight: 400;
  color: white;
  transition: opacity ${animationTime}, transform ${animationTime};
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 30px;
  }

  &:before {
    content: ' ';
    display: block;
    position: absolute;
    bottom: 0;
    width: 30%;
    height: 2px;
    background: white;
    margin-bottom: -10px;
    display: block;
    transition: transform ${animationTime};
    transform: scaleX(${props => (props.underline === 'true' ? 1 : 0)});
    transform-origin: left;
    margin-bottom: -15px;
  }

  &:hover {
    &:before {
      transform: scaleX(1);
    }
  }

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    font-size: 32px;

    &:not(:last-child) {
      margin-bottom: 60px;
    }
  }

  @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
    /* font-size: calc(100vw * 0.025); */
    width: 48%;
  }
`;

// export const MenuContent = styled.div`
//   display: flex;
//   flex-direction: column;
//   min-height: 100vh;
//   width: 100%;

//   @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
//     flex-direction: row;
//     min-height: 0;
//     margin: auto;
//   }

//   @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
//     max-width: 1200px;
//   }
// `;

export const LeftBox = styled.div`
  /* height: 60%; */

  /* margin-top: 10%; */
  flex-grow: 1;
  display: flex;

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    margin-top: 0;
    display: block;

    /* display: grid; */
    /* grid-template-rows: 18% 64% 1fr;
    grid-template-columns: 1fr; */

    /* padding-left: 13%; */

    /* display: -ms-grid;
    -ms-grid-rows: 18% 64% 1fr;
    -ms-grid-columns: 1fr; */

    p:first-of-type {
      margin-top: 0px;
    }
  }

  /* @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
    grid-template-rows: 17% 65% 1fr;

    /* padding-left: 20%; */
  /* } */
`;

export const MenuBox = styled.div`
  margin: auto 0;
  padding-left: 7%;
  width: 100%;

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    padding-left: 15%;
  }
`;

export const RightBox = styled.div`
  width: 50%;
  /* height: 100%; */

  /* display: -ms-grid;
  -ms-grid-rows: 17% 65% 1fr;
  -ms-grid-columns: 1fr; */

  /* display: grid;
  grid-template-rows: 17% 65% 1fr;
  grid-template-columns: 1fr; */

  /* padding-right: 7.5%; */
`;

export const RightBoxMenu = styled(animated.div)`
  width: 100%;
  /* height: 100%; */

  /* -ms-grid-rows: 6% 1% 22% 2% 7.5% 17% 14% 1% 22% 2% 7.5%;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1; */

  /* display: grid; */
  /* grid-template-rows: 6% 1% 22% 2% 7.5% 17% 14% 1% 22% 2% 7.5%; */
  /* grid-row: 2 / span 1; */

  /* @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    [class^='StyledButton'] {
      margin-bottom: 10%;
    }
  } */

  /* @media screen and (min-width: ${props =>
    props.theme.minWidthLaptop}px) { */
    /* -ms-grid-rows: 8% 2% 10% 3% 7% 45% 8% 2% 10% 3% 7%; */

    /* grid-template-rows: 8% 5% 10% 6% 7% 28% 8% 5% 10% 6% 7%; */
  /* } */

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    padding-right: 15%;
  }
`;

export const StyledHR = styled(animated.hr)`
  width: 88%;
  margin-top: 10%;

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    display: none;
  }
`;

export const StyledText = styled(animated.p)`
  font-size: 18px;
  font-weight: normal;
  color: white;
  padding-left: 7%;
  margin-bottom: 15px;
  padding: 20px 0px 20px 7%;

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    /* width: 80%; */
    margin: 0;
    padding-left: 0%;
    line-height: 1.43;
    /* grid-row: ${props => props.gridrow}; */
  }

  @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
    /* width: 45%; */
    line-height: 1.5;
    /* font-size: calc(100vw * 0.011); */
  }

  /* @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-bottom: 2%;
  } */
`;

export const StyledTitle = styled(animated.h4)`
  font-size: 32px;
  font-family: ${props => props.theme.bodyFont};
  font-weight: 400;
  color: white;
  margin-bottom: 15px;

  em {
    margin-right: 4px;
  }
  strong {
    font-weight: 700;
  }
  /* width: 60%; */

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    /* margin: 0; */
    /* width: 66%;
    grid-row: ${props => props.gridrow}; */
  }

  /* @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
    font-size: calc(100vw * 0.025);
  } */

  /* @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-bottom: 3%;
  } */
`;

export const MenuSection = styled.div`
  max-width: 388px;

  &:not(:last-child) {
    margin-bottom: 134px;
  }
  p:not(:last-child) {
    margin-bottom: 15px;
  }

  @media screen and (min-width: 1440px) {
    &:not(:last-child) {
      margin-bottom: 115px;
    }
  }
`;

export const MobileButtonContainer = styled.div`
  display: flex;
  /* padding: 15px 5.6% 15vh; */
  /* padding: 0px 5.6% 24px; */
  justify-content: space-between;
`;

export const SubMenuWrapper = styled.div`
  hr {
    width: 88%;
    margin-block: 0px;

    @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
      display: none;
    }
  }

  button:first-of-type {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
  }

  p {
    font-weight: 300;
    font-size: 16px;
  }

  p,
  button {
    width: 95%;
    padding-left: 7%;
    background: transparent;
    color: #ffffff;
    padding-block: 20px;
    strong {
      font-weight: 600;
    }
  }

  button {
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    img {
      height: 12px;
    }
  }
`;

export const MenuButton = styled(Button)`
  min-width: 130px;
`;

export const MenuWrapper = styled.div`
  display: block;
  color: white;
  font-size: 12px;
  font-family: 'Lato';
  margin-top: 55px;
  margin-left: 20px;

  @media screen and (min-width: 640px) {
    margin-left: 134px;
  }
  @media screen and (min-width: 1440px) {
    width: 120px;
    color: ${props =>
      !props.isSecondary ? '#3D3F46' : 'rgba(255, 255, 255, 0.65)'};
    display: block;
    margin-left: 0px;
  }

  button {
    background: none;
    border: none;
  }
`;
export const SubLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  margin-bottom: 28px;

  @media screen and (min-width: 1440px) {
    gap: 16px;
    margin-bottom: 16px;
  }
`;
export const SubLink = styled.button`
  margin-bottom: 16px;
  letter-spacing: 0.5px;
  width: fit-content;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  text-decoration: ${props => (props.isCurrent ? 'underline' : 'none')};
  text-underline-offset: 4px;
  color: rgba(255, 255, 255, 0.65);
  margin-bottom: 28px;
  padding: 0px;
  margin: 0px;

  &:hover {
    cursor: pointer;
    text-underline-offset: 4px;
    text-decoration: underline;
    text-decoration-color: rgba(255, 255, 255, 0.65);
  }

  @media screen and (min-width: 1440px) {
    font-size: 12px;
    line-height: 12px;
    color: #3d3f46;

    &:hover {
      text-decoration-color: #3d3f46;
    }
  }
`;
export const HeadingLink = styled.a`
  display: block;
  width: fit-content;
  color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
  letter-spacing: 1px;
  margin: 0px;
  margin-bottom: 26px;
  padding: 0px 0px 4px 0px;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    cursor: pointer;
    text-underline-offset: 4px;
    text-decoration: underline;
    text-decoration-color: rgba(255, 255, 255, 0.65);
  }

  @media screen and (min-width: 1440px) {
    color: #91004b;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 16px;

    &:hover {
      text-decoration-color: #91004b;
    }
  }
`;

export const MenuBackdrop = styled.div`
  /* display: none; */

  @media screen and (min-width: 1440px) {
    display: block;
    position: fixed;
    inset: 0;
    backdrop-filter: blur(20px) opacity(0);
    transition: all 0.5s linear;

    &.animate-backdrop {
      background-color: rgba(255, 255, 255, 0.65);
      backdrop-filter: blur(20px) opacity(0.95);
      transition: all 0.3s linear;
    }

    /* if no backdrop filter support: make background less transparent (used when menu is open on screens > 1440px) */
    @supports not (backdrop-filter: blur(20px)) {
      &.animate-backdrop {
        background-color: rgba(255, 255, 255, 0.9);
      }
    }
  }
`;
export const SmallerNavMenuWrapper = styled(NavMenuWrapper)`
  /* @media screen and (min-width: 1440px) {
    display: none;
  } */
`;
export const LargeScreenNavMenuWrapper = styled(NavMenuWrapper)`
  /* display: none; */

  /* @media screen and (min-width: 1440px) {
    display: block;
  } */
`;
